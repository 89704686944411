import { useTranslation } from 'react-i18next';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { formatISODateTime } from '../../../utils/formatters/dateFormatters';
import { formatAmount } from '../../../utils/formatters/amountFormatters';
import { FlexCol, FlexDiv, LabelWrapper } from '../../finance/styles';
import { Label, Table } from '@loomispay/vault';
import { MonthYearPicker } from '../../../components/DatePicker';
import { RetryPayoutButton } from './RetryPayoutButton';

interface GetMonthlySettlementsResponse {
  settlements: Settlement[];
}

interface Settlement {
  id: string;
  status: string;
  description: string;
  paidAmount: number | null;
  salesAmount: number | null;
  transactionFees: number | null;
  transactionFeesVat: number | null;
  subscriptionFees: number | null;
  currency: string;
  createdAt: string | null;
  paidAt: string | null;
  isRetryPossible: boolean;
}

const humanReadableStatus = (status: string): string => {
  if (!status) {
    return '';
  }
  return (status[0].toUpperCase() + status.slice(1, status.length).toLowerCase()).replace('_', ' ');
};

export const MerchantSettlementsTable = () => {
  const { t } = useTranslation();
  const merchantId = useMerchantIdFromUrl();
  const [settlements, setSettlements] = useState<Settlement[]>([]);
  const [monthYear, setMonthYear] = useState<Date>(new Date());

  const formSchema = z.object({
    settlementsMonth: z.date(),
  });

  type FormInputSchema = z.infer<typeof formSchema>;

  const { control, handleSubmit, register, getValues } = useForm<FormInputSchema>({
    defaultValues: {
      settlementsMonth: new Date(),
    },
    resolver: zodResolver(formSchema),
  });

  const fetchSettlements = async () => {
    const month = (monthYear.getMonth() + 1).toString().padStart(2, '0');
    const year = monthYear.getFullYear();

    const url = `/merchants/${merchantId}/settlements/${year}-${month}`;
    const response = await fetchFromBackend(url);

    if (response.ok) {
      const responseBody: GetMonthlySettlementsResponse = await response.json();
      const settlements = responseBody.settlements;

      if (settlements.length === 0) {
        createToast(`${t('settlement.table.empty', { year: year, month: month })}`, {
          timeout: 6000,
          type: 'dark',
        });
      }

      setSettlements(settlements);
    } else {
      createToast(t('settlement.table.error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  const onSubmit = async (data: FormInputSchema) => {
    setMonthYear(data.settlementsMonth);
  };

  const handleChange = () => {
    onSubmit(getValues());
  };

  useEffect(() => {
    fetchSettlements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthYear]);

  const columns = [
    { Header: t('settlement.table.reference'), accessor: 'description' },
    { Header: t('settlement.table.status'), accessor: 'status' },
    { Header: t('settlement.table.created-at'), accessor: 'createdAt' },
    {
      Header: t('settlement.table.paid-at'),
      accessor: 'paidAt',
      Cell: AmountCell,
    },
    {
      Header: t('settlement.table.paid-amount'),
      accessor: 'paidAmount',
      Cell: AmountCell,
    },
    {
      Header: t('settlement.table.sales-amount'),
      accessor: 'salesAmount',
      Cell: AmountCell,
    },
    {
      Header: t('settlement.table.transaction-fees-amount'),
      accessor: 'transactionFees',
      Cell: AmountCell,
    },
    {
      Header: t('settlement.table.transaction-fees-vat-amount'),
      accessor: 'transactionFeesVat',
      Cell: AmountCell,
    },
    {
      Header: t('settlement.table.subscription-fees-amount'),
      accessor: 'subscriptionFees',
      Cell: AmountCell,
    },
    { accessor: 'retry' },
  ];

  const tableData =
    settlements.map(it => ({
      ...it,
      createdAt: formatISODateTime(it.createdAt),
      paidAt: formatISODateTime(it.paidAt),
      status: humanReadableStatus(it.status),
      paidAmount: formatAmount(it.paidAmount, it.currency),
      salesAmount: formatAmount(it.salesAmount, it.currency),
      transactionFees: formatAmount(it.transactionFees, it.currency),
      transactionFeesVat: formatAmount(it.transactionFeesVat, it.currency),
      subscriptionFees: formatAmount(it.subscriptionFees, it.currency),
      retry: it.isRetryPossible && it.id != null && (
        <RetryPayoutButton merchantId={merchantId} payableBatchId={it.id} />
      ),
    })) || [];

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} onChange={handleChange}>
        <FlexDiv>
          <FlexCol>
            <LabelWrapper>
              <Label size={'s'}>{t('settlement.table.month')}</Label>
            </LabelWrapper>
            <MonthYearPicker
              control={control}
              {...register('settlementsMonth', {
                onChange: () => {
                  handleChange();
                },
              })}
            />
          </FlexCol>
        </FlexDiv>
      </form>
      <Table columns={columns} data={tableData} />
    </div>
  );
};

const AmountCell = (props: { value: string }) => {
  return <div style={{ float: 'right' }}>{props.value}</div>;
};
