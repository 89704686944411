import { Button, Heading, Spacing, Tabs, TextInput } from '@loomispay/vault';
import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { useForm, useWatch } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal';
import { FormTextInput } from 'components/TextInput';
import { useState } from 'react';
import { Tab } from 'routes/file-processing/Tabs';
import styled from 'styled-components';
import { createToast } from 'vercel-toast';
import { z } from 'zod';
import { fetchByUrl } from '../../../api';
import american_express from '../details/icons/american_express.png';
import maestro from '../details/icons/maestro.png';
import mastercard from '../details/icons/mastercard.png';
import visa from '../details/icons/visa.png';
import dankortImage from '../details/icons/dankort.png';
import bankaxeptImage from '../details/icons/bank_axept.png';
import { PIcon } from '../details/MerchantScreen.styles';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import { EnableAmexForStore } from './EnableAmexForStore';
import { AmexOnboardingState, Store } from '../../../api/types';
import { DisableAmexForStore } from './DisableAmexForStore';
import { EnabledFeature, useConfiguration } from '../../../configuration';
import { DankortOnboardingButton } from './DankortOnboardingButton';
import { BankAxeptOnboardingButton } from './BankAxeptOnboardingButton';

export const StoreDetailsModal = (props: { selectedStore: Store | null; close: () => void }) => {
  return (
    <Modal isOpen={props.selectedStore !== null} close={props.close} closeButton scrollable={true}>
      {props.selectedStore && <ModalContent store={props.selectedStore} close={props.close} />}
    </Modal>
  );
};

const snakeCaseToTitleCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const ModalContent = (props: { store: Store; close: () => void }) => {
  const tabs = [
    { key: 'details', label: 'Details' },
    { key: 'payment-methods', label: 'Payment methods' },
  ];

  const [selectedTab, setSelectedTab] = useState('details');

  return (
    <div>
      <Heading>Store '{props.store.storeName}'</Heading>
      <Tabs
        tabs={tabs}
        initialActiveTab={selectedTab}
        onClick={(tab: Tab) => {
          setSelectedTab(tab.key);
        }}
      />
      <Spacing bottom="2" />
      {selectedTab === 'details' && <DetailsTab store={props.store} close={props.close} />}
      {selectedTab === 'payment-methods' && <PaymentMethodsTab store={props.store} />}
    </div>
  );
};

const formSchema = z.object({
  name: z.string().min(1).max(255),
});

type FormSchema = z.infer<typeof formSchema>;

const DetailsTab = (props: { store: Store; close: () => void }) => {
  const { control, handleSubmit } = useForm<FormSchema>({
    defaultValues: { name: props.store.storeName },
    resolver: zodResolver(formSchema),
  });
  const watch = useWatch({ control });

  const merchantId = useMerchantIdFromUrl();
  const { permissions } = useUserPermissions();

  const onSubmit = async (formData: FormSchema) => {
    if (props.store.storeName === formData.name) {
      return;
    }

    const response = await fetchByUrl(`/merchants/${merchantId}/stores/${props.store.storeId}/name`, {
      method: 'PUT',
      body: JSON.stringify({
        storeName: formData.name,
      }),
    });

    if (response.ok) {
      createToast('Successfully updated store name', {
        timeout: 6000,
        type: 'success',
      });
      props.close();
    } else {
      createToast('Failed to updated store name', {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacing bottom="2">
        <FormTextInput
          disabled={!hasAnyPermission(permissions, [Permission.MANAGE_MERCHANT])}
          control={control}
          label="Store name"
          name="name"
        />
      </Spacing>
      {Object.keys(props.store)
        .filter(it => it !== 'storeName' && it !== 'merchantId')
        .map((it, index) => (
          <Spacing bottom="2" key={index}>
            <TextInput
              id={it}
              name={it}
              label={snakeCaseToTitleCase(it)}
              value={(props.store[it] as string) ?? ''}
              readOnly={true}
              disabled={true}
            />
          </Spacing>
        ))}
      {hasAnyPermission(permissions, [Permission.MANAGE_MERCHANT]) && (
        <Button type="submit" label="Save changes" disabled={props.store.storeName === watch.name} />
      )}
    </form>
  );
};

const PaymentMethodsTab = (props: { store: Store }) => {
  const { data: configuration } = useConfiguration();

  const permissions = configuration?.permissions || [];
  const enabledFeatures = configuration?.enabledFeatures || [];

  const amexOnboardingState = props.store.amexOnboardingState;

  const enableAmexButtonVisible =
    hasAnyPermission(permissions, [Permission.ENABLE_AMEX]) &&
    (amexOnboardingState === null || amexOnboardingState === AmexOnboardingState.OFFBOARDED);
  const disableAmexButtonVisible =
    enabledFeatures.includes(EnabledFeature.AMEX_OFFBOARDING) &&
    hasAnyPermission(permissions, [Permission.ENABLE_AMEX]) &&
    amexOnboardingState === AmexOnboardingState.ONBOARDED;
  const isStoreOnboardedInDankortOrBankAxept = props.store.baxId !== null;
  const onboardInDankortButtonVisible =
    enabledFeatures.includes(EnabledFeature.ENABLE_DANKORT) &&
    hasAnyPermission(permissions, [Permission.ENABLE_DANKORT]) &&
    props.store.country === 'DK' &&
    !isStoreOnboardedInDankortOrBankAxept;
  const onboardInBankAxeptButtonVisible =
    enabledFeatures.includes(EnabledFeature.ENABLE_BANKAXEPT) &&
    hasAnyPermission(permissions, [Permission.ENABLE_BANKAXEPT]) &&
    props.store.country === 'NO' &&
    !isStoreOnboardedInDankortOrBankAxept;

  return (
    <ButtonWrapper>
      <PIcon src={mastercard} alt={'Mastercard'} />
      <PIcon src={maestro} alt={'Maestro'} />
      <PIcon src={visa} alt={'Visa'} />
      {amexOnboardingState === AmexOnboardingState.ONBOARDED && (
        <PIcon src={american_express} alt={'American Express'} />
      )}
      {isStoreOnboardedInDankortOrBankAxept &&
        ((props.store.country === 'DK' && <PIcon src={dankortImage} alt={'Dankort'} />) ||
          (props.store.country === 'NO' && <PIcon src={bankaxeptImage} alt={'BankAxept'} />))}

      {enableAmexButtonVisible && (
        <EnableAmexForStore storeId={props.store.storeId} storeName={props.store.storeName} />
      )}
      {disableAmexButtonVisible && (
        <DisableAmexForStore storeId={props.store.storeId} storeName={props.store.storeName} />
      )}
      {onboardInDankortButtonVisible && (
        <DankortOnboardingButton storeId={props.store.storeId} storeName={props.store.storeName} />
      )}
      {onboardInBankAxeptButtonVisible && (
        <BankAxeptOnboardingButton storeId={props.store.storeId} storeName={props.store.storeName} />
      )}
    </ButtonWrapper>
  );
};
