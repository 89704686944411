const locale = Intl.NumberFormat().resolvedOptions().locale;
export const formatAmount = (amount: number | null | undefined, currency: string) => {
  if (amount === null || amount === undefined) {
    return '';
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(amount);
};

export const formatPercentage = (percentage: number | null | undefined) => {
  if (percentage === null || percentage === undefined) {
    return '';
  }
  return `${percentage}%`;
};
