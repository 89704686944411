import { Display } from '@loomispay/vault';
import InvoicesSearch from 'components/invoices/InvoicesSearch';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { links } from 'config';
import { hasAnyPermission, Permission, useUserPermissions } from 'permissions';
import { FunctionComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MerchantScreen } from 'routes/merchants/MerchantScreen';
import { SearchScreen } from 'routes/merchants/SearchScreen';
import styled, { css } from 'styled-components';
import { JournalingScreen } from './journaling/JournalingScreen';
import DashboardsScreen from './dashboards/DashboardsScreen';
import FeesScreen from './fees/FeesScreen';
import { FileProcessingScreen } from './file-processing/FileProcessingScreen';
import FinanceReports from './finance/FinanceReports';
import { PayoutOverviewScreen } from './home/PayoutDashboardScreen';
import ReconciliationScreen from './reconciliation/ReconciliationScreen';

export const SEARCH_PATH = '/merchant';
export const HOME_PATH = '/home';

interface AuthorizedRouteProps {
  exact?: boolean;
  path: string;
  requiresAnyOf: Permission[];
}

const AppWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    margin-right: auto;
    padding: ${({ theme }) => `${theme.spacings['4']} ${theme.spacings['4']} ${theme.spacings['3']}`};
  `}
  max-width: 1720px;
`;

export const AuthorizedRoute: FunctionComponent<AuthorizedRouteProps> = ({ exact, path, requiresAnyOf, children }) => {
  const { permissions } = useUserPermissions();

  return (
    <Route exact={exact} path={path}>
      {hasAnyPermission(permissions, requiresAnyOf) ? children : <Display size="s">403 - Unauthorized</Display>}
    </Route>
  );
};

export const AppRouter = () => {
  return (
    <Switch>
      <Route exact path={'/'}>
        <AppWrapper>
          <Redirect to={HOME_PATH} />
        </AppWrapper>
      </Route>
      <Route
        exact
        path="/login"
        component={() => {
          window.location.replace(links.loginForm);
          return null;
        }}
      />
      <Route exact={true} path={HOME_PATH}>
        <AppWrapper>
          <PayoutOverviewScreen />
        </AppWrapper>
      </Route>
      <Route exact={true} path={'/settings'}>
        <AppWrapper>
          <LanguageDropdown />
        </AppWrapper>
      </Route>
      <AuthorizedRoute exact path={SEARCH_PATH} requiresAnyOf={[Permission.READ_MERCHANT]}>
        <AppWrapper>
          <SearchScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute path="/fees" requiresAnyOf={[Permission.READ_FEES, Permission.WRITE_FEES]}>
        <AppWrapper>
          <FeesScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/reconciliation" requiresAnyOf={[Permission.READ_FINANCE]}>
        <AppWrapper>
          <ReconciliationScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/file-processing" requiresAnyOf={[Permission.READ_FILE_PROCESSING]}>
        <AppWrapper>
          <FileProcessingScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute path="/merchant/:id" requiresAnyOf={[Permission.READ_MERCHANT]}>
        <AppWrapper>
          <MerchantScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/invoices" requiresAnyOf={[Permission.READ_INVOICE]}>
        <AppWrapper>
          <InvoicesSearch merchantId={null} />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/reports" requiresAnyOf={[Permission.READ_FINANCE]}>
        <AppWrapper>
          <FinanceReports />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/journaling" requiresAnyOf={[Permission.WRITE_FINANCE]}>
        <AppWrapper>
          <JournalingScreen />
        </AppWrapper>
      </AuthorizedRoute>
      <AuthorizedRoute
        exact
        path="/categories/:category/dashboards/:dashboard?"
        requiresAnyOf={[Permission.DEVELOPMENT]}
      >
        <DashboardsScreen />
      </AuthorizedRoute>
      <Route path="/404">
        <AppWrapper>
          <Display size="s">404 - Page not found</Display>
        </AppWrapper>
      </Route>
      <Redirect to={HOME_PATH} />
    </Switch>
  );
};
