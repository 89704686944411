export interface ListTransactionsRequest {
  filter?: ListTransactionsFilter;
  sorting?: ListTransactionSort[];
  pagination?: PaginationRequest;
}

export interface ListTransactionsFilter {
  search?: string;
  transaction: TransactionFilter;
  settlement: SettlementFilter;
}

interface TransactionFilter {
  id?: string;
  amount?: MoneyFilter;
  timestamp?: TimestampFilter;
  type?: TransactionType[];
  status?: TransactionStatus[];
  cardPresent?: boolean;
}

interface SettlementFilter {
  paidOut?: boolean;
}

interface MoneyFilter {
  gte?: number;
  lte?: number;
}

interface TimestampFilter {
  gte?: Date;
  lt?: Date;
}

interface PaginationRequest {
  pageSize?: number;
  pageNumber: number;
}

export interface ListTransactionsResponse {
  pagination: TransactionsResponsePagination;
  transactions: Transaction[];
}

interface ListTransactionSort {
  on: string;
  dir: ListTransactionSortDirection;
}

export enum ListTransactionSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
interface TransactionsResponsePagination {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
}

export interface Transaction {
  transactionId: string;
  type: string;
  timestamp: string;
  orderId: string;
  terminalId: string;
  amount: number;
  currency: string;
  status: string;
  storeId: string;
  paymentMethod: string;
  cardScheme: string;
  cardType: string;
  issuingCountry: string;
  issuingBank: string;
  lastFourDigitsPan: string;
  cardPresent?: boolean;
}

export enum TransactionType {
  PURCHASE = 'PURCHASE',
  REFUND = 'REFUND',
  REFERENCED_REFUND = 'REFERENCED_REFUND',
  REVERSAL = 'REVERSAL',
  COMPLETION = 'COMPLETION',
  PRE_AUTH = 'PRE_AUTH',
  PURCHASE_REVERSAL = 'PURCHASE_REVERSAL',
  REFUND_REVERSAL = 'REFUND_REVERSAL',
  CHARGEBACK = 'CHARGEBACK',
}

export enum TransactionStatus {
  NEW = 'NEW',
  FAILURE = 'FAILURE',
  PROCESSING = 'PROCESSING',
  DECLINED = 'DECLINED',
  FINISHED = 'FINISHED',
  FAILURE_ACQUIRER = 'FAILURE_ACQUIRER',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
  ON_HOLD = 'ON_HOLD',
}
