import { useTranslation } from 'react-i18next';
import { useMerchantIdFromUrl } from '../merchant-hooks';
import React, { useEffect, useState } from 'react';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';
import { formatISODateTime } from '../../../utils/formatters/dateFormatters';
import { formatAmount, formatPercentage } from '../../../utils/formatters/amountFormatters';
import { Button, Table } from '@loomispay/vault';
import { toUrl } from '../../../utils/formatters/toUrl';
import { saveAs } from 'file-saver';

interface GetTransactionFeeStatementsResponse {
  statements: TransactionFeeStatement[];
}

interface TransactionFeeStatement {
  statementId: string;
  statementNumber: string;
  merchantId: string;
  currency: string;
  period: string;
  createdAt: string;
  statementDate: string;
  transactionFeeAmount: number;
  transactionFeeVatPercentage: number;
  transactionFeeVatAmount: number;
  totalTransactionFeeAmount: number;
}

export const TransactionFeeStatementsTable = () => {
  const { t } = useTranslation();
  const merchantId = useMerchantIdFromUrl();
  const [statements, setStatements] = useState<TransactionFeeStatement[]>([]);

  const fetchStatements = async () => {
    const url = `/merchants/${merchantId}/transaction-fee-statements`;
    const response = await fetchFromBackend(url);

    if (response.ok) {
      const responseBody: GetTransactionFeeStatementsResponse = await response.json();
      if (responseBody.statements.length === 0) {
        createToast(t('merchant.transaction-fee-statements.table.empty'), {
          timeout: 6000,
          type: 'dark',
        });
      }

      setStatements(responseBody.statements);
    } else {
      createToast(t('merchant.transaction-fee-statements.table.error'), {
        timeout: 6000,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchStatements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DownloadStatementButton = (props: { value: string }) => {
    return props.value != null ? (
      <Button
        label={'Download PDF'}
        onClick={() => {
          fetchFromBackend(toUrl(`/merchants/${merchantId}/transaction-fee-statements/${props.value}/pdf`, [])).then(
            async response => {
              if (response.ok) {
                const blob = await response.blob();
                saveAs(blob, `transactionFeeStatement-${props.value}`);
              } else {
                createToast('There was an unexpected error while downloading the statement PDF', {
                  timeout: 6000,
                  type: 'error',
                });
              }
            }
          );
        }}
      />
    ) : null;
  };

  const columns = [
    { Header: t('merchant.transaction-fee-statements.table.statementNumber'), accessor: 'statementNumber' },
    { Header: t('merchant.transaction-fee-statements.table.currency'), accessor: 'currency' },
    { Header: t('merchant.transaction-fee-statements.table.period'), accessor: 'period' },
    { Header: t('merchant.transaction-fee-statements.table.createdAt'), accessor: 'createdAt' },
    { Header: t('merchant.transaction-fee-statements.table.statementDate'), accessor: 'statementDate' },
    { Header: t('merchant.transaction-fee-statements.table.transactionFeeAmount'), accessor: 'transactionFeeAmount' },
    {
      Header: t('merchant.transaction-fee-statements.table.transactionFeeVATPercentage'),
      accessor: 'transactionFeeVatPercentage',
    },
    {
      Header: t('merchant.transaction-fee-statements.table.transactionFeeVATAmount'),
      accessor: 'transactionFeeVatAmount',
    },
    {
      Header: t('merchant.transaction-fee-statements.table.totalTransactionFeeAmount'),
      accessor: 'totalTransactionFeeAmount',
    },
    {
      Header: '',
      accessor: 'statementId',
      Cell: DownloadStatementButton,
    },
  ];

  const tableData =
    statements
      .sort((a, b) => Date.parse(b.statementDate) - Date.parse(a.statementDate))
      .map(it => ({
        ...it,
        createdAt: formatISODateTime(it.createdAt),
        transactionFeeAmount: formatAmount(it.transactionFeeAmount, it.currency),
        transactionFeeVatPercentage: formatPercentage(it.transactionFeeVatPercentage),
        transactionFeeVatAmount: formatAmount(it.transactionFeeVatAmount, it.currency),
        totalTransactionFeeAmount: formatAmount(it.totalTransactionFeeAmount, it.currency),
      })) || [];

  return <Table columns={columns} data={tableData} />;
};
