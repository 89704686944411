import { Button } from '@loomispay/vault';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, useUserPermissions } from '../../../permissions';
import { fetchFromBackend } from '../../../utils/fetch';
import { createToast } from 'vercel-toast';

interface Props {
  merchantId: string;
  payableBatchId: string;
}

export const RetryPayoutButton = ({ merchantId, payableBatchId }: Props) => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <Button
      label={t('settlement.table.retry.button.label')}
      size={'s'}
      variant={'primary'}
      disabled={!permissions.includes(Permission.WRITE_FINANCE) || clicked}
      onClick={() => {
        setClicked(true);
        fetchFromBackend(`/merchants/${merchantId}/payouts/${payableBatchId}/retry`, {
          method: 'POST',
        }).then(async response => {
          if (response.ok) {
            createToast(t('settlement.table.retry.message.success'), {
              timeout: 6000,
              type: 'success',
            });
          } else {
            setClicked(false);
            createToast(t('settlement.table.retry.message.error'), {
              timeout: 6000,
              type: 'error',
            });
          }
        });
      }}
    />
  );
};
